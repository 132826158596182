
body {
  font-family: "Montserrat";
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat-Bold";
}

p {
  color: #8f8f8f;
}

/* Headings */

.big-heading {
  font-family: "Montserrat-Black";
  font-size: 3.5rem;
  line-height: 1.5;
}

.section-heading {
  font-size: 3rem;
  line-height: 1.5;
}

/* Containers */

.container-fluid{
  padding: 7% 15%;
}

/* Sections */

.colored-section {
  background-color: #ff4c68;
  color: #fff;
}

.white-section {
  background-color: #fff;
}

/* Navigation Bar */

.navbar {
  padding: 0 0 4.5rem;
}

.navbar-brand {
  font-family: "Ubuntu";
  font-size: 2.5rem;
  font-weight: bold;
}

.nav-item {
  padding: 0 18px;
}

.nav-link {
  font-size: 1.2rem;
  font-family: "Montserrat-Light";
}

/* Buttons */

.download-button {
  margin: 5% 3% 5% 0;
}

/* Title Section */

#title {
  background-color: #ff4c68;
  color: #fff;
  text-align: left;
}

#title .container-fluid {
  padding: 3% 15% 7%;
}

/* Title Image */

.title-image {
  width: 60%;
  transform: rotate(25deg);
  position: absolute;
  right: 30%;
}

/* Features Section */

#features {
  position: relative;
}

.feature-title {
  font-size: 1.5rem;
}

.feature-box {
  padding: 4.5%;
}

.icon {
  color: #ef8172;
  margin-bottom: 1rem;
}

.icon:hover {
  color: #ff4c68;
}

/* Testimonial Section */

#testimonials {
  background-color: #ef8172;
}

.testimonial-text {
  font-size: 3rem;
  line-height: 1.5;
}

.testimonial-image {
  width: 10%;
  border-radius: 100%;
  margin: 20px;
}


#press {
  background-color: #ef8172;
  padding-bottom: 3%;
}

.press-logo {
  width: 15%;
  margin: 20px 20px 50px;
}

/* Pricing Section */

#pricing {
  padding: 100px;
}

.price-text {
  font-size: 3rem;
  line-height: 1.5;
}

.pricing-column {
  padding: 3% 2%;
}

/* CTA Section */


/* Footer Section */


.social-icon {
  margin: 20px 10px;
}

@media (max-width: 1028px) {

  #title {
    text-align: center;
  }

  .title-image {
    position: static;
    transform: rotate(0);
  }
}
